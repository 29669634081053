:root {
    /* Colors */
    --day-text-icons-text-01: rgba(27, 31, 59);
    --day-text-icons-text-02: rgba(27, 31, 59, 0.65);
    --day-text-icons-text-03: rgba(27, 31, 59, 0.4);
    --day-text-icons-text-01-inverse: #19191c;
    --day-text-icons-text-02-inverse: rgba(255, 255, 255, 0.72);
    --day-text-icons-text-03-inverse: rgba(255, 255, 255, 0.6);
    --day-text-icons-link: #1b1c20;
    --day-text-icons-link-hover: #6c86e2;
    --day-text-icons-text-positive: #3aa981;
    --day-text-icons-text-positive-hover: #7ac5aa;
    --day-text-icons-text-negative: #dd4c1e;
    --day-text-icons-text-negative-hover: #e38163;
    --day-base-base-01: #ffffff;
    --day-base-base-02: #f6f6f6;
    --day-base-base-03: #ededed;
    --day-base-base-04: #d7d7d7; 
    --day-base-base-05: #b0b0b0;
    --day-base-base-06: #959595;
    --day-base-base-07: #808080;
    --day-base-base-08: #333333;
    --day-base-base-09: #000000;
    --day-base-primary: #2E2E2E;
    --day-base-primary-hover: #3E454A;
    --day-base-primary-active: #3E454A;
    --day-base-secondary: #bbff33;
    --day-base-secondary-hover: #acfc0d;
    --day-base-secondary-active: rgba(255, 221, 45, 0.65);
    --day-base-accent: #ff8078;
    --day-base-accent-hover: #ff9a94;
    --day-base-accent-active: #e7716a;
    --day-base-clear: #f6f6f7;
    --day-base-clear-hover: rgba(0, 0, 0, 0.16);
    --day-base-clear-active: rgba(0, 0, 0, 0.2);
    --day-status-sucess-fill: #4ac99b;
    --day-status-success-bg-hover: rgba(74, 201, 155, 0.24);
    --day-status-success-bg: rgba(74, 201, 155, 0.12);
    --day-status-error-fill: #f45725;
    --day-status-error-bg-hover: rgba(244, 87, 37, 0.24);
    --day-status-error-bg: rgba(244, 87, 37, 0.12);
    --day-status-info-fill: #70b6f6;
    --day-status-info-bg-hover: rgba(112, 182, 246, 0.24);
    --day-status-info-bg: rgba(112, 182, 246, 0.12);
    --day-status-warning-fill: #EE9B3A;
    --day-status-warning-bg-hover: rgba(255, 199, 0, 0.24);
    --day-status-warning-bg: rgba(255, 199, 0, 0.12);
    --day-status-neutral-fill: #79818c;
    --day-status-neutral-bg-hover: rgba(121, 129, 140, 0.24);
    --day-status-neutral-bg: rgba(121, 129, 140, 0.12);
    --day-system-selection: rgba(112, 182, 246, 0.12);
    --day-system-focus: rgba(51, 51, 51, 0.64);
    --support-support-01: #a8cef1;
    --support-support-02: #3682db;
    --support-support-03: #8dda71;
    --support-support-04: #34b41f;
    --support-support-05: #e29398;
    --support-support-06: #b8474e;
    --support-support-07: #fcc068;
    --support-support-08: #ff8a00;
    --support-support-09: #dab3f9;
    --support-support-10: #7b439e;
    --support-support-11: #fee797;
    --support-support-12: #fcbb14;
    --support-support-13: #ea97c4;
    --support-support-14: #bd65a4;
    --support-support-15: #7fd7cc;
    --support-support-16: #2fad96;
    --support-support-17: #d4aca2;
    --support-support-18: #9d6f64;
    --support-support-19: #d2e9a2;
    --support-support-20: #aadc42;
    --support-support-21: #a0c5df;
      
    /* Fonts */
    --desktop-heading-1: 800 50px/56px "Manrope", sans-serif;
    --desktop-heading-2: 800 44px/48px "Manrope", sans-serif;
    --desktop-heading-3: 800 36px/40px "Manrope", sans-serif;
    --desktop-heading-4: 800 28px/32px "Manrope", sans-serif;
    --desktop-heading-5: 800 24px/28px "Manrope", sans-serif;
    --desktop-heading-6: 800 20px/24px "Manrope", sans-serif;
    --desktop-body-xl-bold: 800 19px/28px "Manrope", sans-serif;
    --desktop-body-xl: 500 19px/28px "Manrope", sans-serif;
    --desktop-body-l-bold: 600 14px/150% "Manrope", sans-serif;
    --desktop-body-l: 500 17px/28px "Manrope", sans-serif;
    --desktop-body-m-bold: 800 15px/24px "Manrope", sans-serif;
    --desktop-body-m: 500 15px/24px "Manrope", sans-serif;
    --desktop-body-s-bold: 600 13px/130% "Manrope", sans-serif; ;
    --desktop-body-s: 500 13px/20px "Manrope", sans-serif; 
    --desktop-body-xs: 500 11px/16px "Manrope", sans-serif;
  
    /* Effects */
    --input-hover-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
    --shadow-default-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    --shadow-defaulthover-box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.2);
    --shadow-dropdown-box-shadow: 0px 8px 16px 0px rgba(51, 51, 51, 0.2);
    --shadow-input-shadow-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    --shadow-notification-box-shadow: 0px 8px 16px 0px rgba(51, 51, 51, 0.2);
    --shadow-modal-box-shadow: 0px 18px 30px 0px rgba(51, 51, 51, 0.64);
    --shadow-tooltip-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.32),
      0px 6px 15px 0px rgba(0, 0, 0, 0.2);
    --border-day-left-box-shadow: inset -1px 0px 0px 0px rgba(221, 223, 224, 1),
      inset 0px 1px 0px 0px rgba(221, 223, 224, 1),
      inset 0px -1px 0px 0px rgba(231, 232, 234, 1);
    --border-day-right-box-shadow: inset -1px 0px 0px 0px rgba(221, 223, 224, 1);
    --border-day-top-box-shadow: inset 0px 1px 0px 0px rgba(221, 223, 224, 1);
    --border-day-bottom-box-shadow: inset 0px -1px 0px 0px rgba(221, 223, 224, 1);
    --border-day-left-right-box-shadow: inset -1px 0px 0px 0px rgba(231, 232, 234, 1),
      inset 1px 0px 0px 0px rgba(221, 223, 224, 1);
    --border-day-top-bottom-box-shadow: inset -1px 0px 0px 0px rgba(221, 223, 224, 1),
      inset 0px 1px 0px 0px rgba(221, 223, 224, 1),
      inset 0px -1px 0px 0px rgba(221, 223, 224, 1);
    --border-night-right-box-shadow: inset -1px 0px 0px 0px rgba(92, 99, 107, 1);
    --border-night-bottom-box-shadow: inset 0px -1px 0px 0px rgba(92, 99, 107, 1);
    --border-night-left-right-box-shadow: inset -1px 0px 0px 0px rgba(92, 99, 107, 1),
      inset 1px 0px 0px 0px rgba(92, 99, 107, 1);
    --border-night-top-box-shadow: inset 0px 1px 0px 0px rgba(92, 99, 107, 1);
    /* HM */
        /* Colors */  
      --hmnew-green: #bbff33;
      --day-base-base-02-new: #f6f6f7;
      --day-base-primary:  #2c3135;
      /* Fonts */ 
       
      --work-h-1-bold-120-font-family:  700 120px/92px "Bebas Neue", sans-serif; 
      --work-h-2-bold-93-font-family:  700 93px/84px "Bebas Neue", sans-serif; 
      --work-h-3-bold-24-font-family:  700 24px/26px "Bebas Neue", sans-serif; 
      --work-h-4-bold-52-font-family:  700 52px/42px "Bebas Neue", sans-serif; 
      --work-h-5-bold-40-font-family:  700 40px/37px "Bebas Neue", sans-serif; 
      --work-mob-h-1-bold-52-font-family:  700 52px/42px "Bebas Neue", sans-serif; 
      --work-mob-h-2-bold-40-font-family:  700 40px/37px "Bebas Neue", sans-serif;
      --work-mob-h-2_2-bold-40-font-family:  700 40px/37px "Bebas Neue", sans-serif;  
      --work-mob-h-3-bold-20-font-family:  700 20px/20px "Bebas Neue", sans-serif; 
      --work-mob-h-5-bold-24-font-family:  700 24px/26px "Bebas Neue", sans-serif; 
      /* --hmnew-h-1: 400 40px/44px "Unbounded", sans-serif;
      --hmnew-h-2: 400 32px/44px "Unbounded", sans-serif;
      --hmnew-h-3-medium: 500 22px/22px "Manrope", sans-serif;
      --hmnew-p-1-regular: 400 18px/25px "Manrope", sans-serif;
      --hmnew-p-2-regular: 400 14px/20px "Manrope", sans-serif;
      --hmnew-h-4-extra-bold: 800 15px/24px "Manrope", sans-serif;
      --hmnew-p-3-med: 500 12px/20px "Manrope", sans-serif;
      --desktop-body-xs: 600 12px/20px "Manrope", sans-serif;
      --hmnew-p-2-med: 500 14px/20px "Manrope", sans-serif;
      --hmnew-p-1-med: 500 18px/25px "Manrope", sans-serif;
      --hmnew-p-1-16-med: 500 16px/20px "Manrope", sans-serif;  */
    
      /* Effects */ 
      --new-box-shadow: 0px -21px 46px 0px rgba(89, 93, 96, 0.05),
        0px 21px 46px 0px rgba(89, 93, 96, 0.05);
      --cards-box-shadow: 0px 0px 34px 0px rgba(29, 39, 36, 0.07);
  }
  /* @media (max-width: 540px) {
    :root {
      
      --hmnew-h-1: 400 27px/35px "Unbounded", sans-serif;
      --hmnew-h-2: 400 24px/44px "Unbounded", sans-serif;
    }
  } */
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-Regular.ttf') format('woff2'), url('../assets/fonts/Manrope-Regular.ttf') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-Regular.ttf') format('truetype'), url('../assets/fonts/Manrope-Regular.ttf') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-Regular.ttf') format('truetype'), url('../assets/fonts/Manrope-Regular.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-Medium.ttf') format('truetype'), url('../assets/fonts/Manrope-Medium.ttf') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-Bold.ttf') format('woff2'), url('../assets/fonts/Manrope-Bold.ttf') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-ExtraBold.ttf') format('woff2'), url('../assets/fonts/Manrope-Bold.ttf') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Manrope';
    src: url('../assets/fonts/Manrope-ExtraBold.ttf') format('woff2'), url('../assets/fonts/Manrope-Bold.ttf') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
      font-family: 'Unbounded';
      src: url('../assets/fonts/Unbounded-Regular.ttf') format('woff2'),
          url('../assets/fonts/Unbounded-Regular.ttf') format('woff'),
          url('../assets/fonts/Unbounded-Regular.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
  } 
  
  @font-face {
    font-family: 'Bebas Neue';
    src:  
        url('../assets/fonts/BebasNeue-Bold.otf') format('opentype');
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: 92px; /* 76.667% */
    letter-spacing: -4.8px;
    text-transform: uppercase;
}
@media (min-width: 1px) {
  h1 {
    text-align: left;
    font: var(--work-h-1-bold-120-font-family);
    color: var(--day-Base-base-01, #FFF);  
    
  } 
  h2 {
    text-align: left;
    font: var(--work-mob-h-2-bold-40-font-family);   
    align-content: center;
  }
  h3 {
    height: 20px;
    text-align: left;
    font: var(--work-h-3-bold-24-font-family);
    align-content: center;
    
  }
  h4 {
    text-align: left;
    font: var(--work-h-4-bold-52-font-family, 500 22px/22px "Manrope", sans-serif);
    
  }
  h5 {
    text-align: left;
    font: var(--work-h-5-bold-40-font-family);
  }
  *:focus {
    outline: none;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  label{
      
      margin-bottom: 0rem;
  }
  a,
  body,
  div,
  form,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  p,
  ul {
      margin: 0;
      padding: 0;
      border: 0;
      text-decoration: none;
      line-height: 1;
  } 
  small { 
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #3C465C;
  }
  p { 
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
  } 
  span {
    color: var(--hmnew-green);
  }
  body {  
      background: #FAFAFB;
      overflow-x: hidden;
      margin: unset;
  }
  textarea::placeholder {
      color: #3C465C;
      opacity: 0.3;
  }
  ::-webkit-input-placeholder {
      color: #3C465C;
      opacity: 0.6;
  }
  ::-moz-placeholder {
      color: #3C465C;
      opacity: 0.6;
  }
  :-moz-placeholder {
      color: #3C465C;
      opacity: 0.6;
  }
  :-ms-input-placeholder {
      color: #3C465C;
      opacity: 0.6;
  }
  .transit {
      -webkit-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
  }  
  a {
    color: unset;
    cursor: pointer;
  }
  button {
    outline: unset;
    border: unset;
    background: unset;
    border: unset;
    cursor: pointer;
  } 
/* scrool */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #F5F5F5; 
  border-radius: 3px ;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 3px ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      
  background: var(--day-base-primary, #2c3135);
  border-radius: 3px ;
}
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
      -webkit-appearance: none; 
  }
  input{
    margin: unset;
    padding: unset;
    border: unset;
  } 
  .container {
      max-width: 1109px;
      margin: 0 auto;
      padding: 0 30px
  }  
}
  @media screen and (max-width:992px) {
      .container {
          padding: 0 20px
      }
  }
  
@media (min-width: 1px) {
  .body {  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
    isolation: isolate;
        
    background: var(--day-base-base-01);
  }
  .blocks {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px;
    gap: 16px;
    width: 100%; 
    max-width: 1440px;
  }
  .block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: unset;
    gap: 40px;
    width: 100%;

    }
  .size-M-44 {
    height: 44px;
  }
  .size-S-32 {
    height: 32px;
  }
  .size-XS-24 {
    height: 24px;
  }
  .size-L-56 {
    height: 56px;
  }
}
@media (max-width: 1200px) {
  .blocks {
    padding: 0px 20px;
  }
}
@media (max-width: 988px) {
  .blocks {
    padding: 0px 20px;
  }
}
@media (max-width: 768px) {
  .blocks {
    padding: 0px 16px;
  }
} 
@media (max-width: 540px) {
  .blocks {
    padding: 0px 8px;
    gap: 12px;
  }
  .block {
    gap: 32px;
  }
}
/* Animations */
@keyframes slideUp {
  0% {
    transform: translateY(100%); 
  }
  100% {
    transform: translateY(0);  
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}